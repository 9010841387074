.galleries-bg {
  background-color: #40e0d0;
}

.galleries-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 100px);
}

.galleries-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
  font-size: 2.5rem;
}

.galleries-container a {
  color: #fff;
}

.galleries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-items: center;
}

@media (max-width: 768px) {
  .galleries-container {
    padding: 1rem;
  }

  .galleries-title {
    font-size: 2rem;
  }

  .galleries-grid {
    grid-template-columns: 1fr;
  }
} 