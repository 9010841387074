.gallery-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  width: 350px;
  cursor: pointer;
}

.gallery-card:hover {
  transform: translateY(-5px);
}

.gallery-card-hero {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 Aspect Ratio */
  overflow: hidden;
  background-color: #40e0d0;
}

.gallery-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

.gallery-card-image.loading {
  opacity: 0;
}

.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.gallery-card-content {
  padding: 1rem;
}

.gallery-card-title {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.gallery-card-date {
  margin: 0.5rem 0;
  color: #666;
  font-size: 0.9rem;
}

.gallery-card-link {
  background: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gallery-card-link:hover {
  background: #2980b9;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
} 